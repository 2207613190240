
import { Splide } from '@splidejs/splide';
import { classList } from '@common/ts/utils';

// https://splidejs.com/guides/options/

new Splide('.homepage_splide', {
    type: 'slide',
    perPage: 6,
    cloneStatus: false,
    pagination: false,
    perMove: 1,
    padding: { left: '4rem', right: '2rem' },
    clones: 0,
    rewind: true,
    breakpoints: {
        1000: {
            perPage: 4,
        },
        640: {
            perPage: 2,
        },
        440: {
            perPage: 1,
        }
    }
}).mount();

const homepageTabs = document.querySelectorAll('.homepage_splide .nav-link');

const checkActiveTab = (link: HTMLElement) => {
    homepageTabs.forEach((tab: HTMLElement) => {
        if (tab == link) {
            classList(link.parentElement!).add("child_active")
        } else {
            classList(tab.parentElement!).remove("child_active")
        }
    });
}

homepageTabs.forEach((link: HTMLElement) => {
    link.addEventListener(
        'click', () => checkActiveTab(link)
    )
});

checkActiveTab(homepageTabs[0] as HTMLElement);